import { render, staticRenderFns } from "./Inforact.vue?vue&type=template&id=3491bd64&scoped=true"
import script from "./Inforact.vue?vue&type=script&lang=js"
export * from "./Inforact.vue?vue&type=script&lang=js"
import style0 from "./Inforact.vue?vue&type=style&index=0&id=3491bd64&prod&scoped=true&lang=css"
import style1 from "./Inforact.vue?vue&type=style&index=1&id=3491bd64&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3491bd64",
  null
  
)

export default component.exports